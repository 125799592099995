import React, { useEffect, useState } from "react";
import "./CreateGame.css";
import axios from "axios";
import Category from "./Categories";
const API_KEY = process.env.REACT_APP_KEY;
console.log(process.env)
const CreateGame = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<string[]>(["", "", "", "", ""]);
  const [categoryCount, setCategoryCount] = useState(5);
  const [failedCategories, setFailedCategories] = useState<number[]>([]);
  const [failedCategoryNames, setFailedCategoryNames] = useState<
    Record<number, string>
  >({});
  const [newCategoryValues, setNewCategoryValues] = useState<string[]>([]);

  const [generatedQuestions, setGeneratedQuestions] = useState<
    Record<string, any[]>
  >({});

  const handleAddCategory = () => {
    setCategories([...categories, ""]);
    setCategoryCount(categoryCount + 1);
  };

  const handleRemoveCategory = (index: number) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
    setCategoryCount(categoryCount - 1);
  };

  const handleCategoryChange = (index: number, value: string) => {
    const updatedCategories = [...categories];
    updatedCategories[index] = value;
    setCategories(updatedCategories);
  };
  const generateQuestions = async (category: string, index: number) => {
    const generated_prompt = `Create 5 jeopardy 
    style questions in the category of "${category}" along with the answers, make each one successively harder. Output format needs to be. Question: Answer: `;
    console.log("Getting Questions for: ", category);
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: generated_prompt }],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            `Bearer ${API_KEY}`,
        },
      }
    );

    const messageContent = response.data.choices[0].message.content.trim();
    var questionsWithAnswers = messageContent.split("\n\n");
    console.log(questionsWithAnswers)
    if (questionsWithAnswers.length === 6) {
      questionsWithAnswers = questionsWithAnswers.slice(1);
    }

    if (questionsWithAnswers.length === 10) {
      const newList = [];

      for (let i = 0; i < questionsWithAnswers.length; i += 2) {
          const question = questionsWithAnswers[i];
          const answer = questionsWithAnswers[i + 1];
          newList.push(`${question} ${answer}`);
      }
      questionsWithAnswers = newList;
    }

    if (questionsWithAnswers.length > 5) {
      questionsWithAnswers = questionsWithAnswers.splice(5);
    }

    const categoryQuestions = questionsWithAnswers.map(
      (questionWithAnswer: any) => {
        console.log(questionWithAnswer)
        const strWithoutNewlines = questionWithAnswer.replace(/[\r\n]+/g, '');
        console.log(strWithoutNewlines)
        const [question, answer] = strWithoutNewlines.split("Answer");
        const regex = /^(?:Question\s*\d*:?\s*|\d+\s*[^\w\s]\s*Question\s*:?\s*|Question\s+\d+\s*:|#\d+:)/i;
        // const question = inputString.replace(regex, "").trim();
        return {
          question: question ? question.replace(regex, "") : undefined,
          answer: answer ? answer.replace(": ", "") : undefined,
        };
      }
    );
    console.log(categoryQuestions);

    if (categoryQuestions.length < 5) {
      setFailedCategories((prev) => [...prev, index]);
      setFailedCategoryNames((prev) => ({
        ...prev,
        [index]: category,
      }));
      return;
    }

    setGeneratedQuestions((prev) => ({
      ...prev,
      [category]: categoryQuestions.map((question: any, i: number) => ({
        ...question,
        value: (i + 1) * 100,
        flipped: false,
      })),
    }));
  };
  const handleNewCategorySubmit = async (index: number) => {
    const newCategory = newCategoryValues[index].trim();

    if (!newCategory) {
      return;
    }

    const existingCategories = categories.filter(
      (category) => category.trim().toLowerCase() === newCategory.toLowerCase()
    );

    if (existingCategories.length > 0) {
      setNewCategoryValues((prev) => {
        const newValues = [...prev];
        newValues[index] = "";
        return newValues;
      });
      return;
    }

    const newCategories = [...categories];
    newCategories[index] = newCategory;

    setCategories(newCategories);
    setFailedCategories((prev) =>
      prev.filter((failedIndex) => failedIndex !== index)
    );

    await generateQuestions(newCategory, index);
    let parsedNum: number = Number(index);
    if (failedCategories.includes(parsedNum)) {
      console.log("IT DOES INCLUDE");
      setFailedCategoryNames((prev) => {
        const { [index]: deletedCategory, ...rest } = prev;
        return rest;
      });

      setNewCategoryValues((prev) => {
        const newValues = [...prev];
        newValues[index] = "";
        return newValues;
      });
    }

    setFailedCategories(failedCategories.filter((val) => val !== index));
  };

  const handleNewCategoryChange = (index: number, value: string) => {
    setNewCategoryValues((prev) => {
      const newValues = [...prev];
      newValues[index] = value;
      return newValues;
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setFailedCategories([]);
    setFailedCategoryNames({});
    setNewCategoryValues([]);
    // Filter out duplicates and empty categories
    const filteredCategories = categories.filter(
      (category, index) =>
        categories.indexOf(category) === index && category.trim() !== ""
    );
    if (filteredCategories.length === categories.length) {
      const promises = filteredCategories.map((category, index) =>
        generateQuestions(category, index)
      );

      await Promise.all(promises);
      setCategories(["", "", "", "", ""]);
      setNewCategoryValues([]);
    } else {
      const message: string = "Duplicate or empty categories are not allowed";
      window.alert(message);
      console.error("Please remove duplicates or empty categories.");
    }

    setLoading(false);
  };

  const clickedCard = (e: any) => {
    e.currentTarget.style.display = "none";
  };

  const flipCard = (e: any, category: any, i: any) => {
    const updatedQuestions = { ...generatedQuestions };
    updatedQuestions[category][i].flipped =
      !updatedQuestions[category][i].flipped;
    setGeneratedQuestions(updatedQuestions);
  };

  return (
    <div className="game-page">
      <h1 className="page-header"> Jeopardy! </h1>
      <div className="create-game-container">
        {Object.keys(generatedQuestions).length === 0 && (
          <>
            <div className="categories-container">
              {categories.map((category, index) => (
                <Category
                  key={index}
                  index={index}
                  value={category}
                  onCategoryChange={handleCategoryChange}
                  onCategoryRemove={handleRemoveCategory}
                  onNewCategorySubmit={handleNewCategorySubmit}
                  onNewCategoryChange={handleNewCategoryChange}
                  failedCategories={failedCategories}
                  failedCategoryNames={failedCategoryNames}
                  newCategoryValues={newCategoryValues}
                />
              ))}
              {categoryCount < 5 && (
                <button className="add-button" onClick={handleAddCategory}>
                  Add Category
                </button>
              )}
            </div>
            {loading ? (
              <div className="processing-icon"></div>
            ) : (
              <button className="submit-button" onClick={handleSubmit}>
                Generate
              </button>
            )}
          </>
        )}
        <div className="game-board">
          {Object.keys(generatedQuestions).length !== 0 &&
            Object.keys(generatedQuestions).map((category, index) => (
              <div key={index} className="category-questions">
                <h2 className="category-title">{category}</h2>
                {generatedQuestions[category].map((question: any, i) => (
                  <div key={i} className="question-container">
                    <div className="card-value" onClick={clickedCard}>
                      <p>{question.value}</p>
                    </div>
                    <div
                      key={i}
                      className={`question-item ${
                        question.flipped ? "flipped" : ""
                      }`}
                      onClick={(e: any) => {
                        flipCard(e, category, i);
                      }}
                    >
                      <div className="card-container">
                        <div className="card">
                          <div className="front">
                            <p>{question.question}</p>
                          </div>
                          <div className="back">
                            <p>{question.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
        {Object.keys(failedCategoryNames).length !== 0 && (
          <div className="failed-categories">
            <h4>The following categories failed to generate:</h4>
            <ul>
              {Object.keys(failedCategoryNames).map((index) => (
                <li key={index}>{failedCategoryNames[Number(index)]}</li>
              ))}
            </ul>
            <>
              {Object.keys(failedCategoryNames).map(
                (index: any) =>
                  failedCategories.includes(Number(index)) &&
                  failedCategories.length !== 0 && (
                    <div key={index} className="failed-category-container">
                      <h2>{failedCategoryNames[Number(index)]}</h2>
                      <div className="category-item">
                        <input
                          type="text"
                          placeholder="Category"
                          value={newCategoryValues[index] || ""}
                          onChange={(e) =>
                            handleNewCategoryChange(index, e.target.value)
                          }
                        />
                        <button
                          className="submit-new-category"
                          onClick={() => handleNewCategorySubmit(index)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )
              )}
            </>
          </div>
        )}
        {Object.keys(generatedQuestions).length !== 0 && (
          <button
            className="new-game-button"
            onClick={() => setGeneratedQuestions({})}
          >
            New Game
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateGame;
// import React, { useEffect, useState } from "react";
// import "./CreateGame.css";
// import axios from "axios";
// const CreateGame = () => {
//   const [loading, setLoading] = useState(false);
//   const [categories, setCategories] = useState<string[]>(["", "", "", "", ""]);
//   const [categoryCount, setCategoryCount] = useState(5);
//   const [failedCategories, setFailedCategories] = useState<number[]>([]);
//   const [failedCategoryNames, setFailedCategoryNames] = useState<
//     Record<number, string>
//   >({});
//   const [newCategoryValues, setNewCategoryValues] = useState<string[]>([]);

//   const [generatedQuestions, setGeneratedQuestions] = useState<
//     Record<string, any[]>
//   >({});

// const handleAddCategory = () => {
//   setCategories([...categories, ""]);
//   setCategoryCount(categoryCount + 1);
// };

//   const handleRemoveCategory = (index: number) => {
//     const updatedCategories = [...categories];
//     updatedCategories.splice(index, 1);
//     setCategories(updatedCategories);
//     setCategoryCount(categoryCount - 1);
//   };

//   const handleCategoryChange = (index: number, value: string) => {
//     const updatedCategories = [...categories];
//     updatedCategories[index] = value;
//     setCategories(updatedCategories);
//   };

  // const generateQuestions = async (category: string, index: number) => {
  //   const generated_prompt = `Create 5 jeopardy style questions in this category along with the answers, make each one successively harder. Output format needs to be. Question: ,Answer: \n. Category: ${category}`;
  //   console.log("Getting Questions for: ", category);
  //   const response = await axios.post(
  //     "https://api.openai.com/v1/chat/completions",
  //     {
  //       model: "gpt-3.5-turbo",
  //       messages: [{ role: "user", content: generated_prompt }],
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization:
  //           "Bearer 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",
  //       },
  //     }
  //   );

  //   const messageContent = response.data.choices[0].message.content.trim();
  //   var questionsWithAnswers = messageContent.split("\n\n");

  //   if (questionsWithAnswers.length === 6) {
  //     questionsWithAnswers = questionsWithAnswers.slice(1);
  //   }

  //   if (questionsWithAnswers.length > 5) {
  //     questionsWithAnswers = questionsWithAnswers.splice(5);
  //   }

  //   const categoryQuestions = questionsWithAnswers.map(
  //     (questionWithAnswer: any) => {
  //       const [question, answer] = questionWithAnswer.split("\n");

  //       return {
  //         question: question ? question.replace("Question: ", "") : undefined,
  //         answer: answer ? answer.replace("Answer: ", "") : undefined,
  //       };
  //     }
  //   );
  //   console.log(categoryQuestions);

  //   if (categoryQuestions.length < 5) {
  //     setFailedCategories((prev) => [...prev, index]);
  //     setFailedCategoryNames((prev) => ({
  //       ...prev,
  //       [index]: category,
  //     }));
  //     return;
  //   }

  //   setGeneratedQuestions((prev) => ({
  //     ...prev,
  //     [category]: categoryQuestions.map((question: any, i: number) => ({
  //       ...question,
  //       value: (i + 1) * 100,
  //       flipped: false,
  //     })),
  //   }));
  // };
  // const handleNewCategorySubmit = async (index: number) => {
  //   const newCategory = newCategoryValues[index].trim();

  //   if (!newCategory) {
  //     return;
  //   }

  //   const existingCategories = categories.filter(
  //     (category) => category.trim().toLowerCase() === newCategory.toLowerCase()
  //   );

  //   if (existingCategories.length > 0) {
  //     setNewCategoryValues((prev) => {
  //       const newValues = [...prev];
  //       newValues[index] = "";
  //       return newValues;
  //     });
  //     return;
  //   }

  //   const newCategories = [...categories];
  //   newCategories[index] = newCategory;

  //   setCategories(newCategories);
  //   setFailedCategories((prev) =>
  //     prev.filter((failedIndex) => failedIndex !== index)
  //   );

  //   await generateQuestions(newCategory, index);
  //   let parsedNum: number = Number(index);
  //   if (failedCategories.includes(parsedNum)) {
  //     console.log("IT DOES INCLUDE");
  //     setFailedCategoryNames((prev) => {
  //       const { [index]: deletedCategory, ...rest } = prev;
  //       return rest;
  //     });

  //     setNewCategoryValues((prev) => {
  //       const newValues = [...prev];
  //       newValues[index] = "";
  //       return newValues;
  //     });
  //   }

  //   setFailedCategories(failedCategories.filter((val) => val !== index));
  // };
//   const handleNewCategoryChange = (index: number, value: string) => {
//     setNewCategoryValues((prev) => {
//       const newValues = [...prev];
//       newValues[index] = value;
//       return newValues;
//     });
//   };

//   const handleSubmit = async () => {
//     setLoading(true);
//     setFailedCategories([]);
//     setFailedCategoryNames({});
//     setNewCategoryValues([]);
//     // Filter out duplicates and empty categories
//     const filteredCategories = categories.filter(
//       (category, index) =>
//         categories.indexOf(category) === index && category.trim() !== ""
//     );
//     if (filteredCategories.length === categories.length) {
//       const promises = filteredCategories.map((category, index) =>
//         generateQuestions(category, index)
//       );

//       await Promise.all(promises);
//       setCategories(["", "", "", "", ""]);
//       setNewCategoryValues([]);
//     } else {
//       const message: string = "Duplicate or empty categories are not allowed";
//       window.alert(message);
//       console.error("Please remove duplicates or empty categories.");
//     }

//     setLoading(false);
//   };

//   const clickedCard = (e: any) => {
//     e.currentTarget.style.display = "none";
//   };

// const flipCard = (e: any, category:any, i:any) => {

//     const updatedQuestions = { ...generatedQuestions };
//     updatedQuestions[category][i].flipped =
//       !updatedQuestions[category][i].flipped;
//     setGeneratedQuestions(updatedQuestions);
// }
//   return (
//     <div className="game-page">
//       <h2 className="page-header"> Jeopardy! </h2>
//       <div className="create-game-container">
//         {Object.keys(generatedQuestions).length === 0 && (
//           <>
//             <div className="categories-container">
//               {categories.map((category, index) => (
//                 <div key={index} className="category-item">
//                   <input
//                     type="text"
//                     value={category}
//                     onChange={(e) =>
//                       handleCategoryChange(index, e.target.value)
//                     }
//                     placeholder="Category"
//                   />
//                   {categories.length > 1 && (
//                     <button
//                       className="remove-button"
//                       onClick={() => handleRemoveCategory(index)}
//                     >
//                       x
//                     </button>
//                   )}
//                 </div>
//               ))}
//               {categoryCount < 5 && (
//                 <button className="add-button" onClick={handleAddCategory}>
//                   Add Category
//                 </button>
//               )}
//             </div>
//             {loading ? (
//               <div className="processing-icon"></div>
//             ) : (
//               <button className="submit-button" onClick={handleSubmit}>
//                 Generate
//               </button>
//             )}
//           </>
//         )}
//         <div className="game-board">
//           {Object.keys(generatedQuestions).length !== 0 &&
//             Object.keys(generatedQuestions).map((category, index) => (
//               <div key={index} className="category-questions">
//                 <h2 className="category-title">{category}</h2>
//                 {generatedQuestions[category].map((question: any, i) => (
//                   <div key={i} className="question-container">
//                     <div className="card-value" onClick={clickedCard}>
//                       <p>{question.value}</p>
//                     </div>
//                     <div
//                       key={i}
//                       className={`question-item ${
//                         question.flipped ? "flipped" : ""
//                       }`}
//                       onClick={(e:any)=>{flipCard(e,category,i)}}
//                     >
//                       <div className="card-container">
//                         <div className="card">
//                           <div className="front">
//                             <p>{question.question}</p>
//                           </div>
//                           <div className="back">
//                             <p>{question.answer}</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ))}
//         </div>
//         {Object.keys(failedCategoryNames).length !== 0 && (
//           <div className="failed-categories">
//             <h4>The following categories failed to generate:</h4>
//             <ul>
//               {Object.keys(failedCategoryNames).map((index) => (
//                 <li key={index}>{failedCategoryNames[Number(index)]}</li>
//               ))}
//             </ul>
//             <>
//               {Object.keys(failedCategoryNames).map(
//                 (index: any) =>
//                   failedCategories.includes(Number(index)) &&
//                   failedCategories.length !== 0 && (
//                     <div key={index} className="failed-category-container">
//                       <h2>{failedCategoryNames[Number(index)]}</h2>
//                       <div className="category-item">
//                         <input
//                           type="text"
//                           placeholder="Category"
//                           value={newCategoryValues[index] || ""}
//                           onChange={(e) =>
//                             handleNewCategoryChange(index, e.target.value)
//                           }
//                         />
//                         <button
//                           className="submit-new-category"
//                           onClick={() => handleNewCategorySubmit(index)}
//                         >
//                           Submit
//                         </button>
//                       </div>
//                     </div>
//                   )
//               )}
//             </>
//           </div>
//         )}

//         {Object.keys(generatedQuestions).length !== 0 && (
//           <button
//             className="new-game-button"
//             onClick={() => setGeneratedQuestions({})}
//           >
//             New Game
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CreateGame;
