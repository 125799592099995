import React from "react";

interface CategoryProps {
  index: number;
  value: string;
  onCategoryChange: (index: number, value: string) => void;
  onCategoryRemove: (index: number) => void;
  onNewCategorySubmit: (index: number) => void;
  onNewCategoryChange: (index: number, value: string) => void;
  failedCategories: number[];
  failedCategoryNames: Record<number, string>;
  newCategoryValues: string[];
}

const Categories: React.FC<CategoryProps> = ({
  index,
  value,
  onCategoryChange,
  onCategoryRemove,
  onNewCategorySubmit,
  onNewCategoryChange,
  failedCategories,
  failedCategoryNames,
  newCategoryValues,
}) => {
  const handleRemoveClick = () => {
    onCategoryRemove(index);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCategoryChange(index, event.target.value);
  };

  const handleNewCategorySubmit = () => {
    onNewCategorySubmit(index);
  };

  const handleNewCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onNewCategoryChange(index, event.target.value);
  };

  const renderNewCategoryForm = () => {
    if (failedCategories.includes(index)) {
      return (
        <div className="failed-category-container">
          <h2>{failedCategoryNames[index]}</h2>
          <div className="category-item">
            <input
              type="text"
              placeholder="Category"
              value={newCategoryValues[index]}
              onChange={handleNewCategoryChange}
            />
            <button
              className="submit-new-category"
              onClick={handleNewCategorySubmit}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div key={index} className="category-item">
        <div className="cat">
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        placeholder="Category"
      />
        <button className="remove-button" onClick={handleRemoveClick}>
          x
        </button>
      {/* {index !== 0 && (

      )} */}
      {renderNewCategoryForm()}
    </div>
    </div>
  );
};

export default Categories;
